import React, { useEffect, useState } from 'react'
import style from '../style/home.module.css'
import { useDispatch, useSelector } from 'react-redux'
import { Input } from 'antd'
import { FaArrowDownLong } from 'react-icons/fa6'
import { useRouter } from 'next/router'
import Link from 'next/link'
import Head from 'next/head'

export async function getStaticProps() {
  return {
    props: {},
    // Next.js will attempt to re-generate the page:
    // - When a request comes in
    // - At most once every 10 seconds
    revalidate: 60, // In seconds
  }
}

export default function NotFound() {
  const [options, setOptions] = useState([])
  const dispatch = useDispatch()
  const router = useRouter()
  const products = useSelector((state) => state.app.products)
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)

  const buttonSearch = (data) => {
    // dispatch(search(data));
    if (data.trim()) {
      router.push(`/buy-online/${data}`)
    } else {
      router.push(`/search`)
    }
  }

  const onSearch = (searchText) => {
    let option = []
    const newData = products.filter((item) => {
      if (item.name.includes(searchText)) {
        option.push({ value: item.name })
      }
    })
    setOptions(option)
  }

  // if (isAuthenticated) {
  //   router.push('/merchantHome')
  // }
  return (
    <div>
      <Head>
        <title>Agora | Page Not Found</title>
      </Head>
      <div className={style.home}>
        <div className={style.mainContent}>
          <div>
            <h2>
              oops, this page doesn't exist but don't worry — you can search the
              e-commerce web here
            </h2>
            <div className={style.searchArea}>
              {/* <AutoComplete
              options={options}
              style={{
                width: 370,
              }}
              onSelect={onSelect}
              onSearch={onSearch}
            > */}
              <Input.Search
                size='large'
                placeholder='what are you looking for?'
                onSearch={buttonSearch}
              />
              {/* </AutoComplete> */}
              {/* <button className={style.searchIcon}>
              <IoIosSearch />
            </button> */}
            </div>
            <div className={style.notFoundSearchLinks}>
              <Link href='/popular-searches'>popular searches</Link>
              <Link href='/popular-products'>popular products</Link>
              <Link href='/popular-merchants'>popular brands</Link>
            </div>
            {/* <div>
              <a className={style.downArrow} href="#">
                <FaArrowDownLong />
              </a>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  )
}
